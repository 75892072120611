import React, { useState, useEffect } from "react";
import Header from "./HomeComp/Header";
import { Link } from "react-router-dom";
import { RxEyeNone, RxEyeOpen } from "react-icons/rx";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaRegTimesCircle } from "react-icons/fa";
import {
  isCpass,
  isEmail,
  isNum,
  isPass,
  isPhone,
} from "./redux/helpers/form-validator.functions";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { useGoogleLogin } from "@react-oauth/google";
// import { NotificationManager } from "react-notifications";
import { AUTH_LOGIN } from "./redux/constant";
// import { ConnectButton } from "@rainbow-me/rainbowkit";

import {
  N_resendOTP,
  N_sendOTP,
  N_setActivityReport,
  N_userRegister,
  N_verifyOTPNew,
  socialSignup,
  N_sendOTPMobile,
  N_verifyOTPMobile,
} from "./redux/helpers/api_functions_new";
import { FcGoogle } from "react-icons/fc";
// import { namehash } from "viem";
import OtpInput from "react-otp-input";
import { checkEmail, checkPassword } from "./redux/helpers/helper_functions";
import {
  MdDisabledVisible,
  MdOutlineMail,
  MdPhoneIphone,
} from "react-icons/md";
import { LuKey, LuSendHorizonal } from "react-icons/lu";
import OTPInput from "react-otp-input";

export default function Register(props) {
  const [errorMessagephone, setErrorMessagephone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const toggleCollapse = (collapseId) => {
    setOpenCollapse(openCollapse === collapseId ? null : collapseId);
  };
  const [openCollapse, setOpenCollapse] = useState(null);
  const [successMessagephone, setSuccessMessagephone] = useState("");
  const [isMOTPSent, setIsMOTPSent] = useState(false);
  const [motp, setMOtp] = useState();
  const [isMoVerified, setIsMoVerified] = useState(-1);

  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState();
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState();
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [activetab, setActivetab] = useState(0);
  const [referral_code, setReferral] = useState(
    props?.match?.params?.id ? props?.match?.params?.id : ""
  );
  const [agree, setAgree] = useState(false);
  const [checkotp, setCheckOtp] = useState(-1);
  const [loading, setLoading] = useState();
  const [eye, seteye] = useState(false);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [controller, setController] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false); // New state variable to track form submission
  const [isScratched, setIsScratched] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    if (checkotp === 1) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [checkotp]);

  const handleComplete = () => {
    setIsScratched(true);
  };

  // if (isLoggedIn) props.history?.replace("/");
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") === "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };

  const handleVerifyOTP = async (otp) => {
    if (!phone || !motp) return;
    try {
      const res = await N_verifyOTPMobile(responseData.params.token, otp);

      if (res.status === 200) {
        setIsMoVerified(1);
        setSuccessMessagephone(res.message);

        setTimeout(() => {
          dispatch({ type: AUTH_LOGIN, data: responseData });
          N_setActivityReport(responseData.params.token);
          props.history?.replace("/AccountVerification");
        }, 1000);
      } else {
        setIsMoVerified(0);
        setSuccessMessagephone("");
        setErrorMessagephone(res?.message);
      }
    } catch (error) {
      console.error("OTP Verification Error:", error);
      setErrorMessagephone("An error occurred. Please try again.");
    }
  };

  const otpSend = () => {
    if (isEmail(email) && isPass(password)) {
      if (checkEmail(email)) {
        if (checkPassword(password)) {
          if (agree) {
            $(".main_terms").removeClass("empty-red-checkbox");
            setLoading(true);
            if (controller) {
              controller.abort();
            }

            // Create a new controller for the new request
            const newController = new AbortController();
            setController(newController);
            N_userRegister(email, phone, password, referral_code, newController)
              .then((res) => {
                if (res.status === 200) {
                  setIsOTPSent(true);
                }
                setErrorMessage(res.message);
                setLoading(false);
              })
              .catch((e) => {
                console.log("n-user_reg err", e);
              });
          } else {
            setErrorMessage("Please Agree with term and conditions !");
            $(".main_terms").addClass("empty-red-checkbox");
          }
        } else {
          document.getElementById("pass").focus();
        }
      } else {
        document.getElementById("user_email").focus();
      }
    }
    setFormSubmitted(true); // Set formSubmitted to true when submitting the form
  };

  const onSubmit = (newotp) => {
    N_verifyOTPNew(email, phone, newotp).then((res) => {
      if (res.status === 200) {
        setResponseData(res);
        setCheckOtp(1);
      } else {
        setCheckOtp(0);
        setErrorMessage(res.message);
      }
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      socialSignup(codeResponse.access_token, referral_code).then((res) => {
        if (res.status == 200) {
          if (res?.params?.mv == 0) {
            setCheckOtp(1);
            setResponseData(res);
            setIsVisible(true);
          } else {
            dispatch({ type: AUTH_LOGIN, data: res });
            props.history?.replace("/AccountVerification");
          }
        } else {
          setErrorMessage(res.message);
        }
      });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  // create a regex function to check the password contains One Uppercase,one numerical value and length of 8 characters
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    const isValid = passwordRegex.test(password);

    return isValid;
  };
  const handlePassword = (e) => {
    setPassword(isPass(e.target.value));
  };
  return (
    <>
      <Header {...props} />

      <div class="container">
        <div className="page-content">
          <div class="row spacer-sm">
            <div class="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12">
              <div class="d-flex align-items-center">
                <div class="container">
                  <div class="row">
                    {isOTPSent && !isVisible ? (
                      <div class="login_window signupform dis_none_mobile-verify">
                        <div className="page-content-title">
                          <h3 className="mb-lg-5">
                            Enter the 6-digit verification code we sent to{" "}
                            {email ? email : phone}
                          </h3>
                          <h6>For security, we need to verify it's you.</h6>
                        </div>
                        <form
                          className=""
                          method="post"
                          id="signupform"
                          onSubmit={(e) => otpSend(e)}
                        >
                          {/* eroor message */}
                          <div className="my-3">
                            <p className="text-danger mb-0 fs-14" id="msg">
                              {errorMessage}
                            </p>
                          </div>

                          {/* Within the form */}
                          <div className="d-flex justify-content-center-- gap-3 align-items-center">
                            <OtpInput
                              value={otp}
                              onChange={(otp1) => {
                                if (otp1.length == 6) {
                                  onSubmit(otp1);
                                }
                                setOtp(otp1);
                              }}
                              numInputs={6}
                              // inputType="number"
                              renderSeparator={
                                <span
                                  style={{
                                    fontSize: "7px",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                >
                                  {" "}
                                </span>
                              }
                              isDisabled={loading}
                              renderInput={(inputProps, index) => (
                                <input {...inputProps} key={index} />
                              )}
                              inputStyle={{
                                width: "40px",
                                fontWeight: "bold",
                                marginBottom: "10px",
                                height: "40px",
                                borderStyle: "solid",
                                borderWwidth: "1px",
                                borderColor: "#ccc",
                                borderRadius: "5px",
                                backgroundColor: "##F5F6F6",
                                outline: "none",
                              }}
                            />
                            {checkotp == 1 ? (
                              <>
                                <div className="mb-2">
                                  <FaRegCircleCheck className="text-green fs-2" />
                                </div>
                              </>
                            ) : checkotp == 0 ? (
                              <div className="mb-2">
                                <FaRegTimesCircle className="text-danger fs-2" />
                              </div>
                            ) : null}
                          </div>

                          {/* <div className={`form-group`}>
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control"
                                name="user_otp"
                                id="user_otp"
                                required=""
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter Verfication Code"
                              />
                              <span className="input-group-text">
                                <div className="">
                                  <div className="">
                                    <button
                                      className="btn border-0 text-primary border-start rounded-0"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        N_resendOTP(email, phone).then(
                                          (res) => {
                                            setErrorMessage(res.message);
                                          }
                                        );
                                      }}
                                    >
                                      Resend
                                    </button>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div> */}
                          {/* <div className="d-grid mt-3">
                            <button
                              type="button"
                              id="btn_submit"
                              className="btn login_btn"
                              onClick={(e) => {
                                onSubmit(e);
                              }}
                            >
                              {loading ? (
                                <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                              ) : null}
                              <span id="reg">Verify</span>
                            </button>
                          </div> */}
                        </form>
                      </div>
                    ) : !isVisible ? (
                      <div class="login_window signupform cstm_form">
                        <div className="page-content-title">
                          <h3 className="">Create Account</h3>
                        </div>
                        {/* <div className="custom_pills">
                          <nav
                            className="nav nav-pills mb-3"
                            id="myTab"
                            role="tablist"
                          >
                            <button
                              className="nav-link active"
                              id="home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              style={{ height: "28px" }}
                              onClick={() => {
                                setActivetab(0);
                              }}
                            >
                              Email
                            </button>

                            <button
                              className="nav-link"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#profile"
                              style={{ height: "28px" }}
                              type="button"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                              onClick={() => {
                                setActivetab(1);
                              }}
                            >
                              Phone Number
                            </button>
                          </nav>
                        </div> */}

                        <div className="d-grid">
                          <button
                            className="login-with-google-btn"
                            onClick={() => googleLogin()}
                          >
                            <div className="google_icon_bg">
                              <FcGoogle />
                            </div>{" "}
                            Sign up with Google
                          </button>

                          <div id="or">OR</div>
                        </div>

                        <form className="" method="post" id="signupform">
                          {/* eroor message */}
                          <div className="my-3">
                            <p className="text-danger mb-0 fs-14" id="msg">
                              {" "}
                              {errorMessage}
                            </p>
                          </div>
                          {
                            activetab == 0 ? (
                              <div className={`form-group`}>
                                <label
                                  htmlFor="user_password"
                                  className="login_color"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  name="user_email"
                                  autoComplete="off"
                                  readOnly
                                  onFocus={(e) => {
                                    e.target.removeAttribute("readOnly");
                                  }}
                                  value={email}
                                  onChange={(e) =>
                                    setEmail(
                                      isEmail(e.target.value.toLowerCase())
                                    )
                                  }
                                  className="form-control"
                                  id="user_email"
                                  placeholder="eg: youremail@gmail.com"
                                />
                                <small>Error message</small>
                              </div>
                            ) : null
                            // <div className={`form-group`}>
                            //   <input
                            //     type="phone"
                            //     name="user_phone"
                            //     autoComplete="off"
                            //     readOnly
                            //     onFocus={(e) => {
                            //       e.target.removeAttribute("readOnly");
                            //     }}
                            //     value={phone}
                            //     onChange={(e) => setPhone(e.target.value)}
                            //     className="form-control"
                            //     id="user_phone"
                            //     placeholder="Mobile Number"
                            //   />
                            // </div>
                          }

                          <div className={`form-group `}>
                            <label
                              htmlFor="user_password"
                              className="login_color"
                            >
                              Password
                            </label>
                            <div className="input-group">
                              <input
                                type="password"
                                className="form-control"
                                name="user_password"
                                value={password}
                                // onChange={(e) => setPassword(e.target.value)}
                                onChange={handlePassword}
                                id="pass"
                                placeholder="eg: Yourpasscode@123"
                              />
                              <span className="input-group-text">
                                <a
                                  href="#view_qr"
                                  className=""
                                  onClick={(e) => {
                                    hsPassword("pass");
                                  }}
                                >
                                  {eye ? <RxEyeOpen /> : <RxEyeNone />}
                                </a>
                              </span>
                            </div>
                            <small className="text-danger" id="passerr"></small>
                          </div>
                          {/* <div className={`form-group`}>
                       <div className="input-group mb-3">
                         <input
                           type="password"
                           className="form-control"
                           name="user_cpassword"
                           id="cpass"
                           required=""
                           value={confPassword}
                           onChange={(e) => setConfPassword(e.target.value)}
                           placeholder="Confirm Password"
                         />
                         <span className="input-group-text">
                           <a
                             href="#view_qr"
                             className=""
                             onClick={(e) => {
                               hsPassword("cpass");
                             }}
                           >
                             <svg
                               xmlns="http://www.w3.org/2000/svg"
                               width="16"
                               height="16"
                               fill="#666"
                               className="bi bi-eye"
                               viewBox="0 0 16 16"
                             >
                               <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                               <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                             </svg>
                           </a>
                         </span>
                       </div>
                       <small id="cpasserr" className="text-danger"></small>
                     </div> */}
                          <div className="form-group referral_code">
                            <a
                              className="referral_code"
                              data-bs-toggle="collapse"
                              data-bs-target="#referral_code"
                              aria-expanded="false"
                            >
                              Referral Code (Optional)
                              <span class="fa-solid fa-chevron-down ms-2"></span>
                              <span class="fa-solid fa-chevron-up ms-2"></span>
                            </a>
                            {/* <p className="green_text">
                              <FaCheckCircle /> 10% trading fees discount
                              applied (Ref code: USER010)
                            </p> */}
                            <div className="collapse mt-2" id="referral_code">
                              <input
                                type="text"
                                className="form-control"
                                name="refercode"
                                id="refercode"
                                required=""
                                value={referral_code}
                                onChange={(e) => setReferral(e.target.value)}
                                placeholder="Referral code"
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-2">
                            <input
                              className="main_terms"
                              type="checkbox"
                              id="agree_terms"
                              name="agree_terms"
                              checked={agree}
                              onChange={(e) => setAgree(e.target.checked)}
                            />
                            <span className="terms login_color">
                              I agree to the {webData?.website_title}'s{" "}
                              <Link
                                className="text-primary text-decoration-none"
                                to="/terms_&_conditions"
                              >
                                {" "}
                                terms of use{" "}
                              </Link>{" "}
                              and{" "}
                              <Link
                                className="text-primary text-decoration-none"
                                to="/"
                              >
                                {" "}
                                privacy policy.
                              </Link>{" "}
                            </span>
                          </div>
                          <div className="d-grid mt-3">
                            <button
                              type="button"
                              id="btn_submit"
                              className="btn login_btn"
                              onClick={(e) => {
                                otpSend();
                              }}
                            >
                              {loading ? (
                                <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                              ) : null}
                              <span id="reg">Create Account</span>
                            </button>
                          </div>

                          {/* <div className="my-2 text-center">OR</div> */}
                        </form>
                        <div className="d-grid my-3">
                          {/* <ConnectButton.Custom>
                         {({
                           account,
                           chain,
                           openAccountModal,
                           openChainModal,
                           openConnectModal,
                           authenticationStatus,
                           mounted
                         }) => {
                           // Note: If your app doesn't use authentication, you
                           // can remove all 'authenticationStatus' checks
                           const ready =
                             mounted && authenticationStatus !== "loading";
                           const connected =
                             ready &&
                             account &&
                             chain &&
                             (!authenticationStatus ||
                               authenticationStatus === "authenticated");

                           return (
                             <div
                               className="d-grid"
                               {...(!ready && {
                                 "aria-hidden": true,
                                 style: {
                                   opacity: 0,
                                   pointerEvents: "none",
                                   userSelect: "none"
                                 }
                               })}
                             >
                               {(() => {
                                 if (!connected) {
                                   return (
                                     <div className="d-grid mt-3 text-center">
                                       <button
                                         class="btn login_btn_outline"
                                         onClick={openConnectModal}
                                         type="button"
                                       >
                                         <i className="fa fa-wallet fs-5 me-2 text-primary"></i>{" "}
                                         Sign in With Wallet
                                       </button>
                                     </div>
                                   );
                                 }

                                 if (chain.unsupported) {
                                   return (
                                     <button
                                       onClick={openChainModal}
                                       type="button"
                                     >
                                       Wrong network
                                     </button>
                                   );
                                 }

                                 return (
                                   <div style={{ display: "flex", gap: 12 }}>
                                     <button
                                       onClick={openChainModal}
                                       style={{
                                         display: "flex",
                                         alignItems: "center"
                                       }}
                                       type="button"
                                     >
                                       {chain.hasIcon && (
                                         <div
                                           style={{
                                             background: chain.iconBackground,
                                             width: 12,
                                             height: 12,
                                             borderRadius: 999,
                                             overflow: "hidden",
                                             marginRight: 4
                                           }}
                                         >
                                           {chain.iconUrl && (
                                             <img
                                               alt={chain.name ?? "Chain icon"}
                                               src={chain.iconUrl}
                                               style={{
                                                 width: 12,
                                                 height: 12
                                               }}
                                             />
                                           )}
                                         </div>
                                       )}
                                       {chain.name}
                                     </button>

                                     <button
                                       onClick={openAccountModal}
                                       type="button"
                                     >
                                       {account.displayName}
                                       {account.displayBalance
                                         ? ` (${account.displayBalance})`
                                         : ""}
                                     </button>
                                   </div>
                                 );
                               })()}
                             </div>
                           );
                         }}
                       </ConnectButton.Custom> */}

                          <div className="col-lg-12">
                            <div className="fs-12 mt-2 fw-bold">
                              Already have an account? Click here to
                              <Link
                                className="text-primary text-decoration-none"
                                to="/login"
                              >
                                {" "}
                                Log In{" "}
                              </Link>
                            </div>

                            <div className="fs-12 my-3"></div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {checkotp == 1 ? (
                    <>
                      <section>
                        <div className="">
                          <div className="call_action">
                            {/* {profile?.mobile_number ? (
                              <div className="call_action_left">
                                <span>
                                  <i class="fa-solid fa-circle-check text-green me-1"></i>
                                </span>
                                <span className="">
                                  **
                                  {profile?.mobile_number?.toString().slice(-4)}
                                </span>
                              </div>
                            ) : (
                              <div className="call_action_left">
                                <span>
                                  <i class="fa-solid fa-circle-exclamation me-1 text-warning"></i>
                                </span>
                              </div>
                            )} */}

                            {/* <div className="call_action_right">
                              <a
                                data-bs-toggle="collapse"
                                href="#phone_change"
                                className="btn btn-outline-primary"
                                onClick={() => toggleCollapse("phone_change")}
                              >
                                {profile?.mobile_number ? "Update" : "Add"}
                              </a>
                            </div> */}
                          </div>
                        </div>

                        <div className="container">
                          <div className="" id="phone_change">
                            <div className="row my-4 signupform">
                              <div className="col-lg-5 m-auto">
                                <div className="cstm_form">
                                  <div className="d-flex">
                                    <div className="me-3">
                                      <div className="circle_backdrop">
                                        <MdPhoneIphone className="fs-4" />
                                      </div>
                                    </div>
                                    <h4 className="sub_heading mb-3 mt-2">
                                      Verify Phone Number
                                    </h4>
                                  </div>
                                  <div class="">
                                    <div class="mb-4">
                                      <div className="error_div">
                                        {errorMessagephone && (
                                          <h6 className="mb-0 fs-14 text-danger">
                                            {errorMessagephone}
                                          </h6>
                                        )}
                                        {successMessagephone && (
                                          <h6 className="mb-0 fs-14 text-success">
                                            {successMessagephone}
                                          </h6>
                                        )}
                                        {phoneError && (
                                          <p className="text-danger">
                                            {phoneError}
                                          </p>
                                        )}
                                      </div>

                                      <div className="form-group">
                                        <label
                                          for="Fiat"
                                          class="text-muted fs-12"
                                        >
                                          Phone Number
                                        </label>
                                        <div class="input-group">
                                          <input
                                            type="texct"
                                            class="form-control"
                                            value={phone}
                                            id="user_phone"
                                            name="user_phone"
                                            // onChange={handlePhoneChange}
                                            onChange={(e) => {
                                              setPhone(isPhone(e.target.value));
                                            }}
                                            // onWheel={handleWheel}
                                          />
                                          <span class="input-group-text cursor-pointer">
                                            <div
                                              className="text-lgray"
                                              onClick={() => {
                                                if (phone) {
                                                  N_sendOTPMobile(
                                                    responseData?.params?.token,
                                                    phone
                                                  ).then((res) => {
                                                    if (res.status == 200) {
                                                      setIsMOTPSent(true);
                                                      setErrorMessagephone("");
                                                      setSuccessMessagephone(
                                                        res.message
                                                      );
                                                      // setTimeout(() => {
                                                      //   setSuccessMessagephone(
                                                      //     ""
                                                      //   );
                                                      // }, 2000);
                                                    }
                                                  });
                                                }
                                                // else {
                                                //   setErrorMessagephone(
                                                //     "Enter new phone number"
                                                //   );
                                                //   setTimeout(() => {
                                                //     setErrorMessagephone("");
                                                //   }, 4000);
                                                // }
                                              }}
                                            >
                                              {isMOTPSent ? (
                                                <>Resend Code</>
                                              ) : (
                                                <>
                                                  <LuSendHorizonal className="fs-4" />
                                                </>
                                              )}
                                            </div>
                                          </span>
                                        </div>
                                        <small
                                          className="text-danger"
                                          id="phone_err"
                                        ></small>
                                      </div>

                                      {isMOTPSent ? (
                                        <>
                                          <h5 className="hero_heading mb-4 text-center">
                                            Enter the 6- digit code we sent to{" "}
                                            <span className="text-lgray">
                                              {phone}
                                            </span>
                                          </h5>
                                          <div className="d-flex justify-content-center mb-3">
                                            <OTPInput
                                              className="justify-content-center"
                                              value={motp}
                                              onChange={(otp) => {
                                                if (
                                                  /^\d+$/.test(otp) ||
                                                  otp === ""
                                                ) {
                                                  setMOtp(otp);
                                                  if (otp?.length == 6) {
                                                    handleVerifyOTP(otp);
                                                  }
                                                }
                                              }}
                                              numInputs={6}
                                              type="tel"
                                              pattern="[0-9]*"
                                              renderSeparator={
                                                <span
                                                  style={{
                                                    fontSize: "7px",
                                                    marginLeft: "8px",
                                                    marginRight: "8px",
                                                  }}
                                                ></span>
                                              }
                                              isDisabled={loading}
                                              renderInput={(
                                                inputProps,
                                                index
                                              ) => (
                                                <input
                                                  {...inputProps}
                                                  key={index}
                                                  style={{
                                                    width: "40px",
                                                    textAlign: "center",
                                                    marginBottom: "10px",
                                                    height: "40px",
                                                    borderStyle: "solid",
                                                    borderWidth: "1px",
                                                    borderColor: "#ddd",
                                                    color: "#000",
                                                    fontWeight: 600,
                                                    borderRadius: "5px",
                                                    backgroundColor: "#fff",
                                                    outline: "none",
                                                  }}
                                                />
                                              )}
                                            />
                                            {isMoVerified == 1 ? (
                                              <>
                                                <div className="mb-2 mt-2 ms-2">
                                                  <FaRegCircleCheck className="text-green fs-2" />
                                                </div>
                                              </>
                                            ) : isMoVerified == 0 ? (
                                              <div className="mb-2 mt-2 ms-2">
                                                <FaRegTimesCircle className="text-danger fs-2" />
                                              </div>
                                            ) : null}
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                    {/* {isMOTPSent ? (
                                      <div className="text-center mt-2">
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => {
                                            if (phone && motp) {
                                              N_verifyOTPMobile(
                                                responseData.params.token,
                                                motp
                                              ).then((res) => {
                                                if (res.status == 200) {
                                                  setIsMoVerified(true);
                                                  setSuccessMessagephone(
                                                    res.message
                                                  );
                                                  setTimeout(() => {
                                                    dispatch({
                                                      type: AUTH_LOGIN,
                                                      data: responseData,
                                                    });
                                                    N_setActivityReport(
                                                      responseData.params.token
                                                    );
                                                    props.history?.replace(
                                                      "/AccountVerification"
                                                    );
                                                  }, 1000);
                                                } else {
                                                  setSuccessMessagephone("");
                                                  setErrorMessagephone(
                                                    res?.message
                                                  );
                                                  // NotificationManager.error(
                                                  //   "fill all data"
                                                  // );
                                                }
                                              });
                                            }
                                          }}
                                        >
                                          Verify Phone Number
                                        </button>
                                      </div>
                                    ) : null} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12">
              <div className="mt-5 pt-5">
                <div class="col-lg-12">
                  <div className="register_card shadow-md ">
                    <div class="register_img">
                      <img src="/img/shopping.png" class="img-fluid" />
                    </div>
                    <div className="register_content">
                      <h4>Deposit or Buy Crypto</h4>
                      <p>
                        Use UPI/IMPS/NEFT/Cards or directly transfer crypto to
                        your wallet
                      </p>
                    </div>
                  </div>
                  <div className="register_card shadow-md ">
                    <div class="register_img">
                      <img src="/img/issuer.png" class="" />
                    </div>
                    <div className="register_content">
                      <h4>Trade Futures & Option</h4>
                      <p>Unlock the potential of your crypto</p>
                    </div>
                  </div>
                  <div className="register_card shadow-md ">
                    <div class="register_img">
                      <img src="/img/shield.png" class="" />
                    </div>
                    <div className="register_content">
                      <h4>Safe & reliable since 2018</h4>
                      <p>
                        Assets custodied with Fireblocks. Zero security
                        incidents
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
