import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube,
} from "react-icons/io5";
import LogoImage from "../../public/main.png";
import { AiFillTwitterCircle } from "react-icons/ai";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTelegram,
  BsYoutube,
} from "react-icons/bs";
import { adminurl } from "../redux/helpers/api_functions_new";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";

export default function FooterNav() {
  return (
    <>
      <div className="container-fluid footer-bg">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-lg-3 ">
              <div className="">
                <img
                  src={`${adminurl}/images/logo.png`}
                  alt="Logo"
                  className="d-lg-block"
                  // width="20"
                  style={{ height: "30px" }}
                />
                <p className="mt-2 mb-5 fs-12">
                  Empowering Your Cryptocurrency Journey
                </p>
                <div className="social_nav">
                  <a
                    className=""
                    href="https://twitter.com/Dotbloxblochain"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="social_btn">
                      <AiFillTwitterCircle />
                    </div>
                  </a>

                  <a
                    className=""
                    href="https://www.youtube.com/@DTBXBlockchain"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="social_btn">
                      <BsYoutube />
                    </div>
                  </a>

                  <a
                    className=""
                    href="https://www.facebook.com/profile.php?id=61552573607814"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="social_btn">
                      <BsFacebook />
                    </div>
                  </a>

                  <a
                    className=""
                    href="https://www.instagram.com/dtbxblockchain/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="social_btn">
                      <BsInstagram />
                    </div>
                  </a>

                  <a
                    className=""
                    href="https://t.me/+TEXuq_NlgT8zYWE1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="social_btn">
                      <BsTelegram />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-9 mt-4">
              <div className="row footer_row">
                <div className="col-lg-3">
                  <h6>Company</h6>
                  <ul className="text-secondary">
                    <li className="mb-3">
                      <a href="/About">About Us</a>
                    </li>

                    <li className="mb-3">
                      <Link to="/Privacy">Privacy Policy</Link>
                    </li>

                    <li className="mb-3">
                      <Link to="/AmlPrivacy">AML Policy</Link>
                    </li>

                    <li className="mb-3">
                      <Link to="/terms_&_conditions">Terms of services </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h6>Resources</h6>
                  <ul className="text-secondary">
                    <li className="mb-3">
                      <Link to="exchange/trx-inr">Trading</Link>
                    </li>
                    <li className="mb-3">
                      <a href="#">API Docs</a>
                    </li>
                    <li className="mb-3">
                      <a
                        href="/docs/DTBX_Refferal Program PDF.pdf"
                        target="_blank"
                      >
                        Affiliated Program
                      </a>
                    </li>
                    <li className="mb-3">
                      <a href="/docs/Updated  DTBX STAKING.pdf" target="_blank">
                        Staking Program
                      </a>
                    </li>
                    {/* <li className="mb-3">
                <Link to="/FastTrade">Instant Trade</Link>
                </li> */}
                    {/* <li className="mb-3">
                    <a href="#">Options Analytics </a>
                  </li> */}
                  </ul>
                </div>

                <div className="col-lg-3">
                  <h6>Information</h6>
                  <ul className="text-secondary">
                    {/* <li className="mb-3">
                    <a href="#">Contact Specifications</a>
                  </li> */}
                    <li className="mb-3">
                      <Link to="/TradingFee">Trading Fees</Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/AffiliateHome">Referral Program</Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/rewardhub">Rewards </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-lg-3">
                <h6>Protocol</h6>
                <ul className="text-secondary">
                  <li className="mb-3">
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9MhV2s7dSn6uUDZ5RHWXsyDM_9xGlyC599xk2TvGu5AneUg/viewform" target="_blanck">List Your Token</a>
                  </li>
                </ul>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Back to top link */}
      </div>
    </>
  );
}
