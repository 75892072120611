import React, { useState, useEffect } from "react";
import Header from "./HomeComp/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FcGoogle } from "react-icons/fc";
import { RxEyeNone, RxEyeOpen } from "react-icons/rx";
import { RiQrScan2Line } from "react-icons/ri";
import { FaApple, FaRegTimesCircle } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";
import OtpInput from "react-otp-input";
import "./login.css";
import { FaRegCircleCheck } from "react-icons/fa6";
import {
  isEmail,
  isNum,
  isPass,
  isPassu,
  isCpass,
  isPhone,
} from "./redux/helpers/form-validator.functions";
import { user_Login, user_authentication } from "./redux/actions/authActions";
import { useGoogleLogin } from "@react-oauth/google";
// import { adminurl } from "../redux/helpers/api_functions_new";
import {
  N_getGoogleAuth,
  N_resendOTP,
  N_sendOTPMobileEmail,
  N_setActivityReport,
  N_verifyOTPNew,
  socialLogin,
  N_changePassword,
  N_getProfile,
  N_updateName,
  N_verifyPassword,
  N_sendOTPMobile,
  N_sendUpdateEmail,
  N_setGoogleAuth,
  N_setGoogleAuthOTP,
  N_verifyOTPMobile,
  N_verifyUpdateEmail,
} from "./redux/helpers/api_functions_new";
import {
  MdDisabledVisible,
  MdOutlineMail,
  MdPhoneIphone,
} from "react-icons/md";
import OTPInput from "react-otp-input";
import { NotificationManager } from "react-notifications";
import { AUTH_LOGIN } from "./redux/constant";
import { checkEmail, checkPassword } from "./redux/helpers/helper_functions";
import { LuKey, LuSendHorizonal } from "react-icons/lu";

// import { ConnectButton } from "@rainbow-me/rainbowkit";
export default function Login(props) {
  const [errorMessagephone, setErrorMessagephone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const toggleCollapse = (collapseId) => {
    setOpenCollapse(openCollapse === collapseId ? null : collapseId);
  };
  const [openCollapse, setOpenCollapse] = useState(null);
  const [successMessagephone, setSuccessMessagephone] = useState("");
  const [isMOTPSent, setIsMOTPSent] = useState(false);
  const [motp, setMOtp] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [isMoVerified, setIsMoVerified] = useState(-1);
  const [phone1, setPhone1] = useState("");
  const [responseData, setResponseData] = useState(null);

  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  if (isLoggedIn) props.history?.replace("/");
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState("");
  const [activetab, setActivetab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [otp, setotp] = useState();
  const [verifyotp, setverifyOtp] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isOTPSent, setIsOTPSent] = useState(false);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  // const [user, setUser] = useState([]);
  const [eye, seteye] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticator, setAuthenticator] = useState(0);
  const [checkotp, setCheckOtp] = useState(-1);
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };
  const submit = (e) => {
    e.preventDefault();
    // setIsAuthenticating(true);
    // if (!email) {
    //   setErrorMessage("Please enter your email");
    //   setIsAuthenticating(false);
    //   return;
    // }

    // if (!password) {
    //   setErrorMessage("Please enter your password");
    //   setIsAuthenticating(false);
    //   return;
    // }
    if (isEmail(email) && isPass(password)) {
      if (checkEmail(email)) {
        if (checkPassword(password)) {
          N_getGoogleAuth(email, phone, password).then((data) => {
            if (data.status === 200 && data.authenticator_status === 0) {
              setLoading(true);
              dispatch(
                user_Login(
                  email,
                  phone,
                  password,
                  otp,
                  (res) => {
                    setLoading(false);
                    if (res?.params?.mv == 0) {
                      setResponseData(res);
                      setIsVisible(true);
                    } else {
                      dispatch({ type: AUTH_LOGIN, data: res });
                      N_setActivityReport(res.params.token);
                      props.history?.replace("/AccountVerification");
                    }
                  },
                  (res) => {
                    setLoading(false);
                    setErrorMessage(res.message);
                  }
                )
              );
            } else if (data.status === 300) {
              setIsOTPSent(true);
            } else {
              setAuthenticator(data.authenticator_status);
              setErrorMessage(data.message);
            }
          });
        } else {
          document.getElementById("pass").focus();
        }
      } else {
        document.getElementById("user_email").focus();
      }
    }
  };
  const onSubmit = (newotp) => {
    if (authenticator == 2 || authenticator == 1) {
      dispatch(
        user_Login(
          email,
          phone,
          password,
          newotp,
          (res) => {
            if (res?.params?.mv == 0) {
              setCheckOtp(1);
              setResponseData(res);
              setIsVisible(true);
            } else {
              setTimeout(() => {
                dispatch({ type: AUTH_LOGIN, data: res });
                N_setActivityReport(res.params.token);
                props.history?.replace("/AccountVerification");
              }, 1000);
            }
          },
          (res) => {
            setCheckOtp(0);
            setErrorMessage(res.message);
          }
        )
      );
    } else {
      N_verifyOTPNew(email, phone, newotp).then((res) => {
        if (res.status == 200) {
          setCheckOtp(1);
          if (res?.params?.mv == 0) {
            setResponseData(res);
            setIsVisible(true);
          }
          // setTimeout(() => {
          //   dispatch({ type: AUTH_LOGIN, data: res });
          //   N_setActivityReport(res.params.token);
          // }, 1000);
        } else {
          setCheckOtp(0);
          setErrorMessage(res.message);
        }
      });
    }
  };

  const handleVerifyOTP = async (otp) => {
    if (!phone1 || !motp) return;
    try {
      const res = await N_verifyOTPMobile(responseData.params.token, otp);

      if (res.status === 200) {
        setIsMoVerified(1);
        setSuccessMessagephone(res.message);

        setTimeout(() => {
          dispatch({ type: AUTH_LOGIN, data: responseData });
          N_setActivityReport(responseData.params.token);
          props.history?.replace("/AccountVerification");
        }, 1000);
      } else {
        setIsMoVerified(0);
        setSuccessMessagephone("");
        setErrorMessagephone(res?.message);
      }
    } catch (error) {
      console.error("OTP Verification Error:", error);
      setErrorMessagephone("An error occurred. Please try again.");
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      socialLogin(codeResponse.access_token).then((res) => {
        if (res.status == 200) {
          if (res?.params?.mv == 0) {
            setResponseData(res);
            setIsVisible(true);
          } else {
            dispatch({ type: AUTH_LOGIN, data: res });
            props.history?.replace("/AccountVerification");
          }
        } else {
          setErrorMessage(res.message);
        }
      });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <>
      <Header {...props} />

      <div class="container">
        <div className="page-content">
          <div class="row align-items-center">
            <div class="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12">
              <div class="d-flex align-items-center">
                <div class="container">
                  <div class="row">
                    {isOTPSent && !isVisible ? (
                      <div class="login_window signupform">
                        <div className="page-content-title">
                          <h3 className="mb-lg-5">
                            Enter the 6-digit verification code we sent to{" "}
                            {email ? email : phone}
                          </h3>
                          <h6>For security, we need to verify it's you.</h6>
                        </div>
                        <form className="" method="post" id="signupform">
                          {/* eroor message */}
                          <div className="my-3">
                            <p className="text-danger mb-0 fs-14" id="msg">
                              {errorMessage}
                            </p>
                          </div>

                          {/* Within the form */}
                          <div className="d-flex justify-content-center-- gap-3 align-items-center">
                            <OtpInput
                              value={verifyotp}
                              onChange={(otp) => {
                                if (otp.length == 6) {
                                  onSubmit(otp);
                                }
                                setverifyOtp(otp);
                              }}
                              numInputs={6}
                              // inputType="number"
                              renderSeparator={
                                <span
                                  style={{
                                    fontSize: "7px",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                >
                                  {" "}
                                </span>
                              }
                              isDisabled={loading}
                              renderInput={(inputProps, index) => (
                                <input {...inputProps} key={index} />
                              )}
                              inputStyle={{
                                width: "40px",
                                marginBottom: "10px",
                                height: "40px",
                                borderStyle: "solid",
                                borderWwidth: "1px",
                                borderColor: "#ccc",
                                borderRadius: "5px",
                                backgroundColor: "##F5F6F6",
                                outline: "none",
                              }}
                            />
                            {checkotp == 1 ? (
                              <div className="mb-2">
                                <FaRegCircleCheck className="text-green fs-2" />
                              </div>
                            ) : checkotp == 0 ? (
                              <div className="mb-2">
                                <FaRegTimesCircle className="text-danger fs-2" />
                              </div>
                            ) : null}
                          </div>

                          {/* <div className={`form-group`}>
                         <div className="input-group">
                           <input
                             type="number"
                             className="form-control"
                             name="user_otp"
                             id="user_otp"
                             required=""
                             value={otp}
                             onChange={(e) => setOtp(e.target.value)}
                             placeholder="Enter Verfication Code"
                           />
                           <span className="input-group-text">
                             <div className="">
                               <div className="">
                                 <button
                                   className="btn border-0 text-primary border-start rounded-0"
                                   onClick={(e) => {
                                     e.preventDefault();
                                     N_resendOTP(email, phone).then(
                                       (res) => {
                                         setErrorMessage(res.message);
                                       }
                                     );
                                   }}
                                 >
                                   Resend
                                 </button>
                               </div>
                             </div>
                           </span>
                         </div>
                       </div> */}
                          {/* <div className="d-grid mt-3">
                         <button
                           type="button"
                           id="btn_submit"
                           className="btn login_btn"
                           onClick={(e) => {
                             onSubmit(e);
                           }}
                         >
                           {loading ? (
                             <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                           ) : null}
                           <span id="reg">Verify</span>
                         </button>
                       </div> */}
                        </form>
                      </div>
                    ) : (authenticator === 2 || authenticator === 1) &&
                      !isVisible ? (
                      <div class="login_window signupform">
                        <div className="page-content-title">
                          <h3 className="mb-lg-5">
                            Enter the 6-digit verification code we sent to{" "}
                            Google Authenticator App
                          </h3>
                          <h6>For security, we need to verify it's you.</h6>
                        </div>
                        <form className="" method="post" id="signupform">
                          {/* eroor message */}
                          <div className="my-3">
                            <p className="text-danger mb-0 fs-14" id="msg">
                              {errorMessage}
                            </p>
                          </div>

                          {/* Within the form */}
                          <div className="d-flex justify-content-center-- gap-3 align-items-center">
                            <OtpInput
                              value={otp}
                              onChange={(otp1) => {
                                if (otp1.length == 6) {
                                  onSubmit(otp1);
                                }
                                setotp(otp1);
                              }}
                              numInputs={6}
                              // inputType="number"
                              renderSeparator={
                                <span
                                  style={{
                                    fontSize: "7px",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                >
                                  {" "}
                                </span>
                              }
                              isDisabled={loading}
                              renderInput={(inputProps, index) => (
                                <input {...inputProps} key={index} />
                              )}
                              inputStyle={{
                                width: "40px",
                                marginBottom: "10px",
                                height: "40px",
                                borderStyle: "solid",
                                borderWwidth: "1px",
                                borderColor: "#ccc",
                                borderRadius: "5px",
                                backgroundColor: "##F5F6F6",
                                outline: "none",
                              }}
                            />
                            {checkotp == 1 ? (
                              <div className="mb-2">
                                <FaRegCircleCheck className="text-green fs-2" />
                              </div>
                            ) : checkotp == 0 ? (
                              <div className="mb-2">
                                <FaRegTimesCircle className="text-danger fs-2" />
                              </div>
                            ) : null}
                          </div>

                          {/* <div className={`form-group`}>
                         <div className="input-group">
                           <input
                             type="number"
                             className="form-control"
                             name="user_otp"
                             id="user_otp"
                             required=""
                             value={otp}
                             onChange={(e) => setOtp(e.target.value)}
                             placeholder="Enter Verfication Code"
                           />
                           <span className="input-group-text">
                             <div className="">
                               <div className="">
                                 <button
                                   className="btn border-0 text-primary border-start rounded-0"
                                   onClick={(e) => {
                                     e.preventDefault();
                                     N_resendOTP(email, phone).then(
                                       (res) => {
                                         setErrorMessage(res.message);
                                       }
                                     );
                                   }}
                                 >
                                   Resend
                                 </button>
                               </div>
                             </div>
                           </span>
                         </div>
                       </div> */}
                          {/* <div className="d-grid mt-3">
                         <button
                           type="button"
                           id="btn_submit"
                           className="btn login_btn"
                           onClick={(e) => {
                             onSubmit(e);
                           }}
                         >
                           {loading ? (
                             <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                           ) : null}
                           <span id="reg">Verify</span>
                         </button>
                       </div> */}
                        </form>
                      </div>
                    ) : !isVisible ? (
                      <div class="login_window signupform cstm_form">
                        <div className="page-content-title">
                          <h3 className="">Log In</h3>
                        </div>

                        <div className="d-grid">
                          <button
                            className="login-with-google-btn"
                            onClick={() => googleLogin()}
                          >
                            <div className="google_icon_bg">
                              <FcGoogle />
                            </div>{" "}
                            Sign in with Google
                          </button>

                          <div id="or">OR</div>
                        </div>

                        <form
                          className=""
                          method="post"
                          id="loginForm"
                          onSubmit={submit}
                          autoComplete="off"
                        >
                          {/* eroor message */}
                          <div className="my-3">
                            <p className="text-danger mb-0 fs-14" id="msg">
                              {errorMessage}
                            </p>
                          </div>
                          {activetab == 0 ? (
                            <div className="form-group mb-4">
                              <label
                                htmlFor="user_email"
                                className="login_color"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                name="user_email"
                                className="form-control"
                                value={email}
                                autoComplete="off"
                                readOnly
                                onFocus={(e) => {
                                  e.target.removeAttribute("readOnly");
                                }}
                                id="user_email"
                                onChange={(e) =>
                                  setEmail(isEmail(e.target.value))
                                }
                                placeholder="eg: youremail@gmail.com"
                              />
                              <small>Error message</small>
                            </div>
                          ) : (
                            <div className="form-group mb-4">
                              <label>Phone Number</label>
                              <input
                                type="phone"
                                name="user_phone"
                                className="form-control"
                                autoComplete="off"
                                readOnly
                                onFocus={(e) => {
                                  e.target.removeAttribute("readOnly");
                                }}
                                value={phone}
                                id="user_phone"
                                onChange={(e) => setphone(e.target.value)}
                                placeholder="Phone Number"
                              />
                            </div>
                          )}
                          <div className="form-group">
                            <label
                              htmlFor="user_password"
                              className="login_color"
                            >
                              Password
                            </label>

                            <div className="input-group">
                              <input
                                type="password"
                                className="form-control"
                                name="user_password"
                                id="pass"
                                value={password}
                                onChange={(e) =>
                                  setPassword(isPass(e.target.value))
                                }
                                placeholder="eg: yourpassword@123"
                              />
                              <span className="input-group-text">
                                <a
                                  href="#view_qr"
                                  className=""
                                  onClick={(e) => {
                                    hsPassword("pass");
                                  }}
                                >
                                  {eye ? <RxEyeOpen /> : <RxEyeNone />}
                                </a>
                              </span>
                            </div>
                            <small className="text-danger" id="passerr"></small>
                          </div>

                          <div className="d-grid mb-4">
                            <button
                              type="submit"
                              id="login_btn"
                              className="btn login_btn"
                            >
                              {loading ? (
                                <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                              ) : null}
                              <span id="reg">Log In</span>
                            </button>
                          </div>

                          <div className="text-left">
                            <Link
                              to="/forget"
                              className="text-primary text-decoration-none"
                            >
                              Forgot Password ?
                            </Link>
                          </div>
                        </form>

                        <div className="mt-3 fs-12">
                          <span className="fw-bold">Don't have account? </span>
                          <Link
                            className="text-primary text-decoration-none"
                            to="/create"
                          >
                            Sign up now
                          </Link>
                        </div>

                        <div className=" d-flex py-3">
                          <div>
                            <div className="fs-12">
                              Download App{" "}
                              <a href="#">
                                <FaApple className="fs-4 text-dark ms-2" />
                              </a>
                              <a href="#">
                                <FaGooglePlay className="fs-4 text-dark ms-3" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {isVisible ? (
              <section>
                <div className="">
                  <div className="call_action">
                    {/* {profile?.mobile_number ? (
                      <div className="call_action_left">
                        <span>
                          <i class="fa-solid fa-circle-check text-green me-1"></i>
                        </span>
                        <span className="">
                          **
                          {profile?.mobile_number?.toString().slice(-4)}
                        </span>
                      </div>
                    ) : (
                      <div className="call_action_left">
                        <span>
                          <i class="fa-solid fa-circle-exclamation me-1 text-warning"></i>
                        </span>
                      </div>
                    )} */}

                    {/* <div className="call_action_right">
                      <a
                        data-bs-toggle="collapse"
                        href="#phone_change"
                        className="btn btn-outline-primary"
                        onClick={() => toggleCollapse("phone_change")}
                      >
                        {profile?.mobile_number ? "Update" : "Add"}
                      </a>
                    </div> */}
                  </div>
                </div>

                <div className="container">
                  <div className="" id="phone_change">
                    <div className="row my-4 signupform login_window">
                      <div className="col-lg-9 m-auto">
                        <div className="cstm_form">
                          <div className="d-flex">
                            <div className="me-3">
                              <div className="circle_backdrop">
                                <MdPhoneIphone className="fs-4" />
                              </div>
                            </div>
                            <h4 className="sub_heading mb-3 mt-2">
                              Verify Phone Number
                            </h4>
                          </div>
                          <div class="">
                            <div class="mb-4">
                              <div className="error_div">
                                {errorMessagephone && (
                                  <h6 className="mb-0 fs-14 text-danger">
                                    {errorMessagephone}
                                  </h6>
                                )}
                                {successMessagephone && (
                                  <h6 className="mb-0 fs-14 text-success">
                                    {successMessagephone}
                                  </h6>
                                )}
                                {phoneError && (
                                  <p className="text-danger">{phoneError}</p>
                                )}
                              </div>

                              <div className="form-group">
                                <label for="Fiat" class="text-muted fs-12">
                                  Phone Number
                                </label>
                                <div class="input-group">
                                  <input
                                    type="texct"
                                    class="form-control"
                                    value={phone1}
                                    id="user_phone"
                                    name="user_phone"
                                    // onChange={handlePhoneChange}
                                    onChange={(e) => {
                                      setPhone1(isPhone(e.target.value));
                                    }}
                                    // onWheel={handleWheel}
                                  />
                                  <span class="input-group-text cursor-pointer">
                                    <div
                                      className="text-lgray"
                                      onClick={() => {
                                        if (phone1) {
                                          N_sendOTPMobile(
                                            responseData?.params?.token,
                                            phone1
                                          ).then((res) => {
                                            if (res?.status == 200) {
                                              setIsMOTPSent(true);
                                              setErrorMessagephone("");
                                              setSuccessMessagephone(
                                                res?.message
                                              );
                                              // setTimeout(() => {
                                              //   setSuccessMessagephone("");
                                              // }, 4000);
                                            }
                                            // else {
                                            //   setErrorMessagephone(
                                            //     res?.message
                                            //   );
                                            //   setTimeout(() => {
                                            //     setErrorMessagephone("");
                                            //   }, 4000);
                                            // }
                                          });
                                        }
                                      }}
                                    >
                                      {isMOTPSent ? (
                                        <>Resend Code</>
                                      ) : (
                                        <>
                                          <LuSendHorizonal className="fs-4" />
                                        </>
                                      )}
                                    </div>
                                  </span>
                                </div>
                                <small
                                  className="text-danger"
                                  id="phone_err"
                                ></small>
                              </div>

                              {isMOTPSent ? (
                                <>
                                  <h5 className="hero_heading mb-4 text-center">
                                    Enter the 6- digit code we sent to{" "}
                                    <span className="text-lgray">{phone1}</span>
                                  </h5>
                                  <div className="d-flex justify-content-center mb-3">
                                    <OTPInput
                                      className="justify-content-center"
                                      value={motp}
                                      onChange={(otp) => {
                                        if (/^\d+$/.test(otp) || otp === "") {
                                          setMOtp(otp);
                                          if (otp?.length == 6) {
                                            handleVerifyOTP(otp);
                                          }
                                        }
                                      }}
                                      type="tel"
                                      pattern="[0-9]*"
                                      numInputs={6}
                                      renderSeparator={
                                        <span
                                          style={{
                                            fontSize: "7px",
                                            marginLeft: "8px",
                                            marginRight: "8px",
                                          }}
                                        ></span>
                                      }
                                      isDisabled={loading}
                                      renderInput={(inputProps, index) => (
                                        <input
                                          {...inputProps}
                                          key={index}
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                            marginBottom: "10px",
                                            height: "40px",
                                            borderStyle: "solid",
                                            borderWidth: "1px",
                                            borderColor: "#ddd",
                                            color: "#000",
                                            fontWeight: 600,
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            outline: "none",
                                          }}
                                        />
                                      )}
                                    />
                                    {isMoVerified == 1 ? (
                                      <>
                                        <div className="mb-2 mt-2 ms-2">
                                          <FaRegCircleCheck className="text-green fs-2" />
                                        </div>
                                      </>
                                    ) : isMoVerified == 0 ? (
                                      <div className="mb-2 mt-2 ms-2">
                                        <FaRegTimesCircle className="text-danger fs-2" />
                                      </div>
                                    ) : null}
                                  </div>
                                </>
                              ) : null}
                            </div>
                            {/* {isMOTPSent ? (
                              <div className="text-center mt-2">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    if (phone1 && motp) {
                                      N_verifyOTPMobile(
                                        responseData.params.token,
                                        motp
                                      ).then((res) => {
                                        if (res.status == 200) {
                                          setIsMoVerified(true);
                                          setSuccessMessagephone(res.message);
                                          setTimeout(() => {
                                            dispatch({
                                              type: AUTH_LOGIN,
                                              data: responseData,
                                            });
                                            N_setActivityReport(
                                              responseData.params.token
                                            );
                                            props.history?.replace(
                                              "/AccountVerification"
                                            );
                                          }, 1000);
                                        } else {
                                          setSuccessMessagephone("");
                                          setErrorMessagephone(res?.message);
                                          // NotificationManager.error(
                                          //   "fill all data"
                                          // );
                                        }
                                      });
                                    }
                                  }}
                                >
                                  Verify Phone Number
                                </button>
                              </div>
                            ) : null} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            {!isVisible ? (
              <div class="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12">
                {/* <h4 className="qrscan_heading"> Quick Log In - Scan using App</h4> */}
                <h4 className="qrscan_heading">
                  {" "}
                  Quick Log In - Get the Mobile App Soon!
                </h4>

                {/* <div className="d-flex justify-content-center align-items-center login_left_bg">
    <div class="qr-container">
      <img src="/img/qrcode.png" />
      <div class="marker"></div>
    </div>
  </div>
  <div className="py-3">
    <p className="fs-12 fw-bold">                  
      <RiQrScan2Line className="text-primary fs-4" /> Scan QR code
      with the latest {webData?.website_title} mobile app to Log In.
    </p>
  </div> */}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
