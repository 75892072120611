import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";

export default function AssetsTradingAccount() {
  const { webData } = useSelector((state) => state.websiteDBReducer);
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="marginTop ">
            <div className="account_page">
              <div className="container">
                <div className="page-content-title">
                  <h3 className="">Trading Account</h3>
                </div>

                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <span class="desc_card">0.00</span>
                        <span class="fs-16 text-muted ms-1">BTC</span>
                        <span class="fs-16 text-lgray">
                          <span>≈ 0.00</span>
                          <span>USD</span>
                        </span>
                        <span class="fs-16 text-pink ms-2">
                          <span class="interests_SddH3">(Equity:</span>
                          <span>0.00 BTC</span>)
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <a href="#" className="btn btn-light anchor_link">
                        Account Details
                      </a>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="row">
                    <div className="activity_panel mb-4">
                      <div className="search_input_grp">
                        <div class="input-group">
                          <span class="input-group-text">
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Search"
                            class="form-control text-start"
                          />
                        </div>
                      </div>

                      <div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox1"
                            value="option1"
                          />
                          <label class="form-check-label" for="inlineCheckbox1">
                            Hide small balances
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox2"
                            value="option2"
                          />
                          <label class="form-check-label" for="inlineCheckbox2">
                            {webData?.website_title} Earn assets
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-12">
                    <div className="alert alert-success">
                      <div className="d-flex">
                        <div>
                          <i class="fa-regular fa-circle-check text-green me-2"></i>
                        </div>
                        <div className="text-lighter">
                          {" "}
                          To trade tokens, click Transfer to move the assets
                          from your Funding Account to your Trading Account.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th>Coin</th>
                          <th>Total</th>
                          <th>Available Amount</th>
                          <th>In Orders</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center">
                              <div>
                                <img
                                  src="https://assets-currency.kucoin.com/6342262ad1c44e00010ca166_200x200.png"
                                  alt="coin symbole"
                                  class="thumb_img"
                                />
                              </div>
                              <div class="product_name">
                                <div class="mb-0 fw-bold">00</div>
                                <div class="text-muted fs-12"> Polls</div>
                              </div>{" "}
                            </div>
                          </td>
                          <td>
                            <div class="mb-0 fw-bold">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0 fw-bold">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0 fw-bold">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>

                          <td>
                            <a href="#" class="anchor_link">
                              Details
                            </a>
                            <div class="verticle_line"></div>
                            <a href="/exchange/aco-inrx" class="anchor_link">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center mw-150px">
                              <div>
                                <img
                                  src="https://assets-currency.kucoin.com/60bf85728afb0a00068efcf6_1INCH.png"
                                  alt="coin symbole"
                                  class="thumb_img"
                                />
                              </div>
                              <div class="product_name">
                                <div class="mb-0 fw-bold">1INCH </div>
                                <div class="text-muted fs-12"> 1INCH Token</div>
                              </div>{" "}
                            </div>
                          </td>
                          <td>
                            <div class="mb-0 fw-bold">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0 fw-bold">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0 fw-bold">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>

                          <td>
                            <a href="#" class="anchor_link">
                              Details
                            </a>
                            <div class="verticle_line"></div>
                            <a href="/exchange/aco-inrx" class="anchor_link">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center">
                              <div>
                                <img
                                  src="https://assets-currency.kucoin.com/6196181fb26db300061312bd_Logo%20color.png"
                                  alt="coin symbole"
                                  class="thumb_img"
                                />
                              </div>
                              <div class="product_name">
                                <div class="mb-0 fw-bold">1EARTH</div>
                                <div class="text-muted fs-12"> EarthFund</div>
                              </div>{" "}
                            </div>
                          </td>
                          <td>
                            <div class="mb-0 fw-bold">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0 fw-bold">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0 fw-bold">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>

                          <td>
                            <a href="#" class="anchor_link">
                              Details
                            </a>
                            <div class="verticle_line"></div>
                            <a href="/exchange/aco-inrx" class="anchor_link">
                              Trade
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
