import React, { useState, useEffect } from "react";
import $ from "jquery";
import { FaArrowUp, FaLongArrowAltUp } from "react-icons/fa";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import CalltoAction from "./CalltoAction";
import FooterNav from "./FooterNav";
import { NotificationManager } from "react-notifications";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { getRound } from "../redux/helpers/helper_functions";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaRegTimesCircle } from "react-icons/fa";
import {
  N_getStake,
  N_sendUpdateEmail,
  N_setStake,
} from "../redux/helpers/api_functions_new";
import { Helmet } from "react-helmet";
import OTPInput from "react-otp-input";
import { LiaMoneyBillSolid } from "react-icons/lia";
import { MdSecurity } from "react-icons/md";
import { FaChartLine, FaCoins, FaTelegram } from "react-icons/fa";

const StakingPlans = (props) => {
  const { user, profile, isLoggedIn } = useSelector(
    (state) => state.AuthReducer
  );
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const { wallet, coins } = useSelector((state) => state.coinDBReducer);
  const token = user?.params ? user.params.token : user.token;
  let stake_data = webData && webData?.stake;
  const [loading, setLoading] = useState(false);
  const [claimloading, setcliamLoading] = useState(false);
  const [errorHarvestMessage, setHarvestMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [amount, setAmount] = useState();
  const [endAmount, setendAmount] = useState(0);
  const [interest, setInterrest] = useState(0);
  const [interest_rate, setInterrestRate] = useState(0);
  const [interestAmount, setInterrestAmount] = useState(0);
  const [subscriptionDate, setSubscriptionDate] = useState("DD/MM/YYYY");
  const [endSubscriptionDate, setEndSubscriptionDate] = useState("DD/MM/YYYY");
  const [agree, setAgree] = useState(false);
  const [stake_coin, setStakeCoin] = useState({});
  const [activeTradeIndex, setActiveTradeIndex] = useState({});
  const [controller, setController] = useState(null);
  const [notp, setNOtp] = useState(null);
  const [isNOTPSent, setIsNOTPSent] = useState(false);
  const [checkotp, setCheckOtp] = useState(-1);
  const [ry, setRy] = useState({
    total_ry: 0,
    symbol: "",
  });
  const onSubmit = (notp, bal) => {
    if (notp) {
      if (bal >= amount) {
        setLoading(true);
        let days = stake_coin?.days;
        let wallet_type = stake_coin?.symbol;
        if (controller) {
          controller.abort();
        }

        // Create a new controller for the new request
        const newController = new AbortController();
        setController(newController);
        N_setStake(token, wallet_type, amount, days, notp, newController).then(
          (res) => {
            setLoading(false);
            setIsNOTPSent(false);
            if (res.status == 200) {
              setCheckOtp(1);
              NotificationManager.success(res.message);
            } else {
              setCheckOtp(0);
              NotificationManager.error(res.message);
            }
            setErrorMessage(res.message);
            setTimeout(() => {
              setNOtp(null);
              setCheckOtp(-1);
              setErrorMessage("");
            }, 1000);
          }
        );
      } else {
        setErrorMessage("Amount is greater than Available balance");
      }
    } else {
      setErrorMessage("Enter Otp");
    }
  };
  const totalIncome =
    (webData?.staking_income?.[1] ?? 0) +
    (webData?.staking_income?.[2] ?? 0) +
    (webData?.staking_income?.[3] ?? 0);

  const renderData =
    stake_data &&
    Object.keys(stake_data).map((keys, i) => {
      let coin_data = stake_data[keys];

      let coindata = wallet && wallet?.find((item) => item.symbol == keys);
      if (!coindata) {
        let coins1 = Object.values(coins);
        coindata = coins1 && coins1.find((item) => item.symbol == keys);
      }
      let icon = coindata?.icon;
      let name = coindata?.name;
      let bal = coindata?.balance - coindata?.locked;
      coin_data.sort((a, b) => a.days - b.days);
      let tradeData =
        Array.isArray(coin_data) && coin_data?.length > 0
          ? coin_data.map((trade, j) => {
              return (
                <div
                  key={j}
                  className={`btn btn-sm duration-btn cursor-pointer ${
                    !activeTradeIndex[keys] && j == 0
                      ? "active"
                      : activeTradeIndex[keys] === j
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setStakeCoin({ ...trade, symbol: keys });
                    setActiveTradeIndex((prevState) => ({
                      ...prevState,
                      [keys]: j,
                    })); // Update active trade index
                    N_getStake(token, keys, trade?.days).then((res) => {
                      if (res.status == 200) {
                        let data = res.result;
                        setRy({
                          total_ry: data.total_ry,
                          totalStakeValue: data.totalStakeValue,
                          symbol: keys,
                        });
                      }
                    });
                  }}
                >
                  {trade?.days} days
                </div>
              );
            })
          : null;
      return (
        <>
          <div className="application">
            <Helmet>
              <meta
                name="description"
                content="Explore the market for seamless and secure crypto trading. Dive
into the world of Crypto Trading and elevate your trading experience. "
              />
              <title>
                Unlock {`${webData?.website_title}`} Staking Rewards in Staking
                Pool
              </title>
            </Helmet>
          </div>

          <div key={i} class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <div class="ana-tb-item">
                <div class="ana-tb-col w3">
                  <div className="d-flex align-items-center gap-2">
                    <div className="width35">
                      <img
                        src={icon}
                        alt="coin symbole"
                        className="market_coin_img"
                      />
                    </div>
                    <div className="product_name">
                      <div className="fw-bold text-dark">{keys}</div>
                      <div className="text-muted fs-12"> {name}</div>
                    </div>
                  </div>
                </div>
                <div class="ana-tb-col w4 cus-txt">
                  <div class="ana-tab-content">
                    {stake_coin?.symbol == keys
                      ? stake_coin?.percent
                      : coin_data[0]?.percent}
                    %
                  </div>
                </div>
                <div class="ana-tb-col w5">
                  <div class="ana-tab-content">
                    <div className="d-flex gap-2 align-items-center">
                      <div class="minWidthCol">
                        <div class="d-flex gap-1 flex-wrap">{tradeData}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ana-tb-col w3">
                  <div class="ana-tab-content">
                    {stake_coin?.symbol == keys
                      ? stake_coin?.minimum_stake
                      : coin_data[0]?.minimum_stake}{" "}
                    {keys}
                  </div>
                </div>

                <div class="ana-tb-col w2">
                  <div class="ana-tab-content">
                    {isLoggedIn ? (
                      <button
                        class="btn-valid "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${keys}`}
                        aria-expanded="true"
                        aria-controls={keys}
                        onClick={() => {
                          if (stake_coin?.symbol != keys) {
                            setStakeCoin({ ...coin_data[0], symbol: keys });
                            N_getStake(token, keys, coin_data[0]?.days).then(
                              (res) => {
                                if (res.status == 200) {
                                  let data = res.result;
                                  setRy({
                                    total_ry: data.total_ry,
                                    totalStakeValue: data.totalStakeValue,
                                    symbol: keys,
                                  });
                                }
                              }
                            );
                          }
                        }}
                      >
                        Enroll Now
                      </button>
                    ) : (
                      <button
                        class="action-btn staking-btn"
                        type="button"
                        onClick={() => {
                          props.history?.replace("/login");
                        }}
                      >
                        Enroll Now
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </h2>
            <div
              id={keys}
              class="accordion-collapse collapse"
              data-bs-parent="#satkingAccordion"
            >
              <div class="accordion-body staking-accordion-body border rounded">
                <div class="row cstm_form">
                  <div className="col-lg-6">
                    <div className="p-4">
                      <div className="form-group mb-4">
                        <label class="small text-muted">
                          Available Balance
                        </label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            readOnly
                            value={bal}
                          />
                          <span class="input-group-text">Add More</span>
                        </div>
                      </div>
                      <p className="text-danger mb-0 fs-14"> {errorMessage}</p>
                      <div className="form-group mb-4">
                        <label class="small text-muted">
                          Subscription Amount
                        </label>
                        <div class="input-group">
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Enter Amount"
                            value={amount}
                            onChange={(e) => {
                              if (stake_coin?.days) {
                                setAmount(e.target.value);
                                // Calculate the interest
                                const interest =
                                  (parseFloat(e.target.value) *
                                    parseFloat(stake_coin?.percent)) /
                                  100;
                                // Calculate the end volume
                                const endVolume =
                                  parseFloat(e.target.value) +
                                  parseFloat(interest);
                                setInterrest(
                                  interest > 0 ? getRound(interest) : 0
                                );
                                setendAmount(
                                  endVolume > 0 ? getRound(endVolume) : 0
                                );

                                // Calculate the fixed interest
                                //  const fixedInterest = interest * (parseFloat(stake_coin?.days) / 365); // Assuming interest is calculated annually
                                //  setInterrestRate(fixedInterest>0?getRound(fixedInterest):0)
                                // Calculate the estimated total amount
                                //  const estimatedTotalAmount = parseFloat(e.target.value) + parseFloat(fixedInterest);
                                // Current date
                                const startDate = new Date();
                                const endDate = new Date(startDate);
                                endDate.setDate(
                                  startDate.getDate() + stake_coin?.days
                                );

                                // Format dates for display
                                const formattedStartDate =
                                  startDate.toLocaleDateString();
                                const formattedEndDate =
                                  endDate.toLocaleDateString();
                                setSubscriptionDate(formattedStartDate);
                                setEndSubscriptionDate(formattedEndDate);
                                setInterrestAmount(
                                  endVolume > 0 ? getRound(endVolume) : 0
                                );
                              } else {
                                setErrorMessage("Please Select Days");
                              }
                            }}
                          />
                          <span class="input-group-text">
                            <span class="input-group-text">{keys}</span>
                          </span>
                        </div>
                      </div>
                      {/* <p className="text-danger mb-0 fs-14">
                    {" "}
                    {errorHarvestMessage}
                  </p> */}
                      <div className="d-flex justify-content-between mb-5">
                        <div>
                          {" "}
                          Minimum:{" "}
                          {stake_coin?.symbol == keys
                            ? stake_coin?.minimum_stake
                            : coin_data[0]?.minimum_stake}{" "}
                          {keys}
                        </div>
                        <div>
                          Total Running Stak :{" "}
                          {ry.symbol == keys ? ry.totalStakeValue : 0} {keys}
                        </div>
                      </div>
                      {/*
                  {ry.symbol == keys && ry.total_ry>0?
                  <div className="d-flex justify-content-between">
                    <div> Total Intrest Income: {ry.total_ry} {keys}</div>
                    <div> 
                      
                    {claimloading?
                <div class="spinner-border text-success align-center" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>:
                      <button class="btn btn-success" 
                  onClick={()=>{
                        setcliamLoading(true)
                        let days = stake_coin?.days;
                        let symbol = stake_coin?.symbol;
                        let harvest_amount = ry.total_ry;
                        N_getHarvest(token, symbol, days, harvest_amount)
                        .then((res1)=>{
                          if(res1.status==200) {
                            setTimeout(()=>{
                              N_getStake(token, symbol, days)
                              .then((res)=>{
                                if(res.status == 200) {
                                  let data = res.result;
                                  setRy({total_ry: data.total_ry, symbol:keys})
                                }
                              })
                            }, 1000)
                            
                          } else {
                            setHarvestMessage(res1.message)
                            setTimeout(()=>{
                              setHarvestMessage("")
                            }, 1000)
                          }
                          setcliamLoading(false)
                        })
                   
                  }}>Claim Now</button>}</div> 
                  </div>:null}*/}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="p-4">
                      <div class="p-4 shadow-4 rounded-3 bg-gredient-primary">
                        <h2 className="fs-16 fw-bold">Summary</h2>

                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="hero_heading">Start Volume</div>
                            <div>{amount}</div>
                          </div>
                          <div>
                            <div className="hero_heading">End Volume</div>
                            <div>{endAmount}</div>
                          </div>
                          <div>
                            <div className="hero_heading">Interest</div>
                            <div>{interest}</div>
                          </div>
                        </div>
                      </div>

                      <div className="info-wrap">
                        <div class="infoLists">
                          <div class="listInfo">
                            <span class="listInfo-title">
                              Subscription Date:
                            </span>
                            <span class="listInfo-text">
                              {subscriptionDate}
                            </span>
                          </div>
                          <div class="listInfo">
                            <span class="listInfo-title">
                              End Subscription Date:
                            </span>
                            <span class="listInfo-text">
                              {endSubscriptionDate}
                            </span>
                          </div>
                          <div class="listInfo">
                            <span class="listInfo-title">Credit In: </span>
                            <span class="listInfo-text">Everyday</span>
                          </div>
                          <div class="listInfo">
                            <span class="listInfo-title">
                              Est. Total Amount
                            </span>
                            <span class="listInfo-text">
                              {endAmount} {keys}
                            </span>
                          </div>
                          <div class="listInfo">
                            <span class="listInfo-title">Withdrawal Date:</span>
                            <span class="listInfo-text">Anytime</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-check">
                        <input
                          class="form-check-input main_terms"
                          type="checkbox"
                          checked={agree}
                          onChange={(e) => setAgree(e.target.checked)}
                          id="termsChecked"
                        />
                        <label
                          class="form-check-label fs-12 "
                          for="termsChecked"
                        >
                          I agree with{" "}
                          <Link to="/terms_&_conditions">
                            terms & conditions
                          </Link>
                        </label>
                      </div>

                      <div class="d-grid mt-4">
                        {isNOTPSent ? (
                          <>
                            <div class="mb-4">
                              <h5 className="hero_heading mb-2 text-center">
                                Enter the 6- digit code we sent to{" "}
                                <span className="text-lgray">
                                  {profile?.email}
                                </span>
                              </h5>
                              <div className="d-flex justify-content-center ml-2">
                                <OTPInput
                                  value={notp}
                                  onChange={(otp) => {
                                    if (/^\d+$/.test(otp) || otp === "") {
                                      if (otp.length == 6) {
                                        onSubmit(otp, bal);
                                      }
                                      setNOtp(otp);
                                    }
                                  }}
                                  type="tel"
                                  pattern="[0-9]*"
                                  numInputs={6}
                                  renderSeparator={
                                    <span
                                      style={{
                                        fontSize: "7px",
                                        marginLeft: "8px",
                                        marginRight: "8px",
                                      }}
                                    ></span>
                                  }
                                  isDisabled={loading}
                                  renderInput={(inputProps, index) => (
                                    <input
                                      {...inputProps}
                                      key={index}
                                      style={{
                                        width: "40px",
                                        textAlign: "center",
                                        marginBottom: "10px",
                                        height: "40px",
                                        borderStyle: "solid",
                                        borderWidth: "1px",
                                        borderColor: "#ddd",
                                        color: "#000",
                                        fontWeight: 600,
                                        borderRadius: "5px",
                                        backgroundColor: "#fff",
                                        outline: "none",
                                      }}
                                    />
                                  )}
                                />
                                {checkotp == 1 ? (
                                  <div className="mb-2">
                                    <FaRegCircleCheck className="text-green fs-2" />
                                  </div>
                                ) : checkotp == 0 ? (
                                  <div className="mb-2">
                                    <FaRegTimesCircle className="text-danger fs-2" />
                                  </div>
                                ) : null}
                              </div>
                              {/* <div className="text-center mt-2">
                                {loading ? (
                                  <div
                                    class="spinner-border text-success align-center"
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      if (notp) {
                                        if (bal >= amount) {
                                          setLoading(true);
                                          let days = stake_coin?.days;
                                          let wallet_type = stake_coin?.symbol;
                                          if (controller) {
                                            controller.abort();
                                          }

                                          // Create a new controller for the new request
                                          const newController =
                                            new AbortController();
                                          setController(newController);
                                          N_setStake(
                                            token,
                                            wallet_type,
                                            amount,
                                            days,
                                            notp,
                                            newController
                                          ).then((res) => {
                                            setLoading(false);
                                            setIsNOTPSent(false);
                                            if (res.status == 200) {
                                              NotificationManager.success(
                                                res.message
                                              );
                                            } else {
                                              NotificationManager.error(
                                                res.message
                                              );
                                            }
                                            setErrorMessage(res.message);
                                            setTimeout(() => {
                                              setErrorMessage("");
                                            }, 1000);
                                          });
                                        } else {
                                          setErrorMessage(
                                            "Amount is greater than Available balance"
                                          );
                                        }
                                      } else {
                                        setErrorMessage("Enter Otp");
                                      }
                                    }}
                                  >
                                    Submit
                                  </button>
                                )}
                              </div> */}
                            </div>
                          </>
                        ) : (
                          <button
                            class="btn btn-primary"
                            onClick={() => {
                              if (stake_coin) {
                                if (bal >= amount) {
                                  if (!agree) {
                                    $(".main_terms").addClass(
                                      "empty-red-checkbox"
                                    );
                                  } else {
                                    N_sendUpdateEmail(
                                      user?.params
                                        ? user.params.token
                                        : user.token,
                                      profile?.email,
                                      0
                                    ).then((res) => {
                                      if (res.status == 200) {
                                        setIsNOTPSent(true);
                                        NotificationManager.success(
                                          res.message
                                        );
                                        setErrorMessage(res.message);
                                      }
                                    });
                                  }
                                } else {
                                  setErrorMessage(
                                    "Amount is greater than Available balance"
                                  );
                                }
                              } else {
                                setErrorMessage("Please Select Days");
                              }
                            }}
                          >
                            Stake Now
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    });

  return (
    <>
      <Header />
      <div className="page-content">
        <div className="container market_trend spacer-top">
          <div class="market-text">
            <div className="staking-plan-flex">
              <div className="staking-plan">
                <h2>Staking Plans</h2>
                <p>
                  Simply and securely buy, sell, and manage hundreds of
                  currencies.
                </p>
              </div>
              <div className="history-btn">
                <Link to="/SpotOrders">
                  <button className="action-btn">History</button>
                </Link>
              </div>
            </div>
            <div class="spacer-sm staking_wrapper">
              <div class="ana-tb-list is-separate">
                <div class="ana-tb-item ana-tb-head">
                  <div class="ana-tb-col w3">Coin/Token</div>
                  <div class="ana-tb-col w4">
                    <div class="ana-tab-content">
                      <div className="ana_label">Est. APR</div>
                    </div>
                  </div>
                  <div class="ana-tb-col w5">
                    <div class="ana-tab-content">
                      <div className="ana_label">Duration</div>
                    </div>
                  </div>
                  <div class="ana-tb-col w3">
                    <div class="ana-tab-content">
                      <div className="ana_label">Min Amount </div>
                    </div>
                  </div>

                  <div class="ana-tb-col w2">
                    <div class="ana-tab-content">
                      <div className="ana_label">Action</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion accordion-flush" id="satkingAccordion">
                {renderData}
              </div>
            </div>
          </div>
          <div className="spacer">
            <div className="container">
              <div className="row  align-items-center">
                <div className="col-lg-4 col-12">
                  <div className="pe-5">
                    <h2>What You Can Earn ?</h2>
                    <p className="text-lgrey">
                      Earn upto{" "}
                      <span className="text-highlight">
                        {" "}
                        {(webData?.staking_income?.[1] ?? 0) +
                          (webData?.staking_income?.[2] ?? 0) +
                          (webData?.staking_income?.[3] ?? 0)}
                        % Commission{" "}
                      </span>{" "}
                      through 3 levels. <br></br>
                      {webData?.staking_income?.[1] ?? 0}% on level one,{" "}
                      {webData?.staking_income?.[2] ?? 0}% on level two,
                      {webData?.staking_income?.[3] ?? 0}% on level three.
                    </p>{" "}
                  </div>

                  {/* <div className="col-lg-12">
                  <a href="#" className="action-btn me-3">
                    Start Earning Today!
                  </a>
                </div> */}
                </div>
                <div className="col-lg-8 col-12">
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <div className="custom_card">
                        <div className="logo">
                          <div class="featured_icon">
                            <MdSecurity />
                          </div>
                        </div>
                        <div>
                          <div>
                            <h4>Tiered Commissions</h4>
                            <p>
                              Earn commissions up to {totalIncome}% across three
                              levels of referrals, rewarding you for your
                              efforts in growing our community.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="custom_card">
                        <div className="logo">
                          <div class="featured_icon">
                            <LiaMoneyBillSolid />
                          </div>
                        </div>

                        <h4>Unlimited Earning Potential</h4>
                        <p>
                          There's no cap on how much you can earn. The more you
                          refer and the more your referrals trade, the more you
                          earn!
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="custom_card">
                        <div className="logo">
                          <div class="featured_icon">
                            <FaChartLine />
                          </div>
                        </div>
                        <div>
                          <h4>Be Your Own Boss</h4>
                          <p>
                            Take control of your financial destiny and become
                            your own boss with our flexible and rewarding
                            referral program.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-8">
          <div className="row spacer">
         
          </div>
          </div> */}
              </div>
            </div>
          </div>
          <div className="spacer">
            <div className="container">
              <div className="para_heading">Frequently Asked Questions</div>
              <p className="text-left text-lgray">
                Frequently asked questions (FAQs) for instant trading
              </p>

              <div className="row mt-5">
                <div class="accordion accordion-flush" id="faqAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What is staking?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <p>
                          Staking involves participating in a proof-of-stake
                          (PoS) blockchain network by holding and locking up a
                          certain amount of cryptocurrency to support the
                          network's operations and validate transactions. In
                          return, participants may receive rewards in the form
                          of additional cryptocurrency.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How does staking work?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        Staking works by holding a certain amount of
                        cryptocurrency in a designated wallet or staking
                        platform and actively participating in the network's
                        consensus mechanism. Participants are chosen to validate
                        transactions and create new blocks based on the amount
                        of cryptocurrency they hold and stake.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What are the benefits of staking?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        Staking offers several benefits, including the
                        opportunity to earn rewards in the form of additional
                        cryptocurrency, support for the security and
                        decentralization of the blockchain network, and
                        potential for passive income generation.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4"
                      >
                        What are staking plans?
                      </button>
                    </h2>
                    <div
                      id="collapse4"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading5"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        Staking plans are strategies or programs offered by
                        cryptocurrency projects or staking platforms that
                        outline the terms and conditions for staking
                        participation. These plans may include details such as
                        staking requirements, rewards structure, lock-up
                        periods, and any associated fees.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                      >
                        How do I choose a staking plan?
                      </button>
                    </h2>
                    <div
                      id="collapse5"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        When choosing a staking plan, consider factors such as
                        the cryptocurrency project's reputation, staking rewards
                        potential, staking requirements (e.g., minimum stake
                        amount), lock-up periods, and the overall viability of
                        the project.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        How do I participate in a staking plan?
                      </button>
                    </h2>
                    <div
                      id="collapse6"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        To participate in a staking plan, you typically need to
                        hold the required amount of cryptocurrency in a
                        compatible wallet or staking platform, follow the
                        instructions provided by the project or platform to
                        stake your coins, and monitor your staking rewards.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse7"
                      >
                        Is staking safe?
                      </button>
                    </h2>
                    <div
                      id="collapse7"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        Staking can be a safe and secure way to earn rewards on
                        your cryptocurrency holdings, especially if you choose
                        reputable projects or staking platforms with strong
                        security measures in place. However, it's essential to
                        do your research and understand the risks involved
                        before participating in any staking plan.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CalltoAction />
      <FooterNav />
      <Footer />
    </>
  );
};

export default StakingPlans;
