import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Lottie from "react-lottie";
import imagereward from "./Reward.json";
import couponScratched from "./DiscountCard.json";
import Header from "./Header";
import Footer from "./Footer";
import FooterNav from "./FooterNav";
import { Link } from "react-router-dom/cjs/react-router-dom";
import IMG from "../../public/scratch-card.jpg";
import CalltoAction from "./CalltoAction";
import ScratchCard from "react-scratchcard-v2";
import { Image } from "react-bootstrap";
import { N_getRewardData } from "../redux/helpers/api_functions_new";
import { Helmet } from "react-helmet";
import { LiaMoneyBillSolid } from "react-icons/lia";
import { MdSecurity } from "react-icons/md";
import { FaChartLine, FaCoins, FaTelegram } from "react-icons/fa";

export default function RewardHub() {
  const { user, isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: imagereward, // the animation data
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const ref = useRef < ScratchCard > null;

  const onClickReset = () => {
    ref.current && ref.current.reset();
  };

  const [isScratched, setIsScratched] = useState(false);
  const [rewardcoin, setRewardCoin] = useState("");
  const [rewardfee, setrewardfee] = useState(0);
  const [rewarddata, setRewardData] = useState(null);
  const [rewardUserEarning, setrewardUserEarning] = useState(null);
  const [scratchedText, setScratchedText] = useState(
    "Congratulations! You WON!"
  );
  const handleScratchComplete = () => {
    console.log("The card is now clear!");
    setScratchedText("Congratulations! You WON!");
  };
  const geht = false;
  const settings = {
    width: 320,
    height: 200,
    image: IMG,
    finishPercent: 60,
    onComplete: () => handleScratchComplete(),
  };

  useEffect(() => {
    N_getRewardData(user?.params ? user.params.token : user.token).then(
      (res) => {
        setRewardCoin(res.signup_coin);
        setrewardfee(res.signup_commision);
        setRewardData(res.reward);
        setrewardUserEarning(res.total_earning);
      }
    );
  }, [isLoggedIn]);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content="Explore the market for seamless and secure crypto trading. Dive
into the world of Crypto Trading and elevate your trading experience. "
          />
          <title>Earn Rewards | {`${webData?.website_title}`} Exchnage</title>
        </Helmet>
      </div>
      <Header />
      <div className="page-content">
        <div className="bg-gredient-primary spacer-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-7 col-sm-12 col-md-7 mb-3">
                <div className="">
                  <h3 className="page_main_heading">
                    <span class="text-highlight">Earn attractive rewards </span>
                    in <br />
                    Rewards Hub
                  </h3>
                  {/* <p>Grab the reward before they ran out!</p>
                  <div className="mb-4">
                    <span className="custom_badge bg-soft-gray">
                      Earn upto ₹100 worth {webData?.referral_coin} on each
                      referral
                    </span>
                  </div>
                  {rewardUserEarning?.length ? (
                    <div class="row hero_stats">
                      {rewardUserEarning?.map((item) => (
                        <div class="col-lg-4 col-6">
                          <div class="fs-3 fw-bold">{item?.total}</div>
                          <div class="hero_text">Total {item?._id} *</div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div class="row hero_stats">
                      <div class="col-lg-4 col-6">
                        <div class="fs-3 fw-bold">0</div>
                        <div class="hero_text">Total {rewardcoin} *</div>
                      </div>
                      <div class="col-lg-4 col-6">
                        <div class="fs-3 fw-bold">0 </div>
                        <div class="hero_text">Total SHIB*</div>
                      </div>
                    </div>
                  )}

                  <p className="fs-12 text-lgray mb-0">
                    *Sign Up and Kyc bonus (21 {webData?.airdrop_coin} + and
                    100000 Shiba Inu) will be useable by User after trade of
                    5000 USDT.
                  </p>
                  <p className="fs-12 text-lgray mb-0">
                    *Referral bonus ({webData?.referral_coin} worth of ₹100)
                    will be useable by User after trade of 1000 USDT by your
                    Referral User.
                  </p> */}
                  <div className="spacer">
                    <div className="container ">
                      <div className="row  align-items-center">
                        <div className="col-12">
                          <div className=" pe-5">
                            <h4 className="page_main_heading">
                              <span className="text-highlight">
                                Sign up Level
                              </span>
                            </h4>
                            <h2>What You Can Earn ?</h2>
                            <p className="text-lgrey">
                              Earn upto{" "}
                              {webData?.airdrop_fee -
                                1 +
                                (webData?.airdrop_fee - 2) +
                                (webData?.airdrop_fee - 3)}{" "}
                              <span className="text-highlight"> DTBX </span>{" "}
                              through 3 levels. <br></br>
                              {webData?.airdrop_fee - 1} on level one,{" "}
                              {webData?.airdrop_fee - 2} on level two,{" "}
                              {webData?.airdrop_fee - 3} on level three.
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 col-sm-12 col-md-5 text-end d-none d-lg-block d-md-block">
                {/* <Lottie options={defaultOptions} height={400} width={400} /> */}
                <img src="/img/referralLevel.jpeg" className="img-fluid "></img>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container">
          <div className="row spacer-sm">
            <div className="col-lg-9">
              <h3>Get Rewards</h3>
              <p className="text-lgray">
                Shop for reward and claim them with your points
              </p>
            </div>
            <div className="col-lg-3 text-end">
              <div className="recieved_amt">
                You have recieved
                <i class="fa-regular fa-circle-question ms-1"></i>
              </div>
              {rewarddata &&
                rewarddata.map((item, index) => (
                  <div key={index} className="recieved_amt_value">
                    <span className="text-highlight">
                      {item?.status ? item?.commission : 0}
                    </span>{" "}
                    <span className="text-lgray">{item?.wallet_type}</span>
                  </div>
                ))}
            </div>
          </div>
        </div> */}

        <div className="container spacer">
          <div className="col-lg-12 text-center ">
            <h2 class="para_heading mb-0"> Help Center </h2>
            <p className="text-lgray">Frequently Asked questions</p>
          </div>
          <div className="col-lg-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What are new user rewards?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    New user rewards are rewards provided to new{" "}
                    {webData?.website_title} users. Rewards are given to new
                    users when they complete their sign-up, first deposit/crypto
                    purchase, first trade, and pro trading. Rewards include USDT
                    and coupons, worth up to 3200 USDT. Users must complete the
                    corresponding tasks within 60 days after signing up for
                    their {webData?.website_title} account in order to receive
                    the rewards. Each reward can only be received once by each
                    user.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Who is eligible to participate and receive new user rewards?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    The following types of users are eligible to participate and
                    receive new user rewards: (1) Users who signed up for their
                    {webData?.website_title} accounts after 08:00:00 (UTC) May
                    23, 2023. (2) Users who signed up for their{" "}
                    {webData?.website_title} accounts after 08:00:00 (UTC) March
                    1, 2023 and have not yet made their first deposit or crypto
                    purchase.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    What are rewards for withdrawal?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    To qualify for withdrawal, accumulate the required amount of
                    crypto rewards within 60 days of signing up for your{" "}
                    {webData?.website_title}
                    account. Ensure you withdraw within the specified time
                    frame, as failure to do so will result in the inability to
                    withdraw those crypto rewards. The rewards will be credited
                    to your Funding Account within 14 working days after
                    initiating a withdrawal. Contact customer service if you do
                    not receive your rewards within this time frame.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    New User Rewards Details
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <p>
                      {" "}
                      Sign-Up Reward: Sign up for a {
                        webData?.website_title
                      }{" "}
                      account to receive a USDT reward. The reward amount will
                      be randomly determined within a set range.
                    </p>
                    <p>
                      {" "}
                      First Deposit/Buy Crypto Reward: Deposit or buy crypto for
                      the first time (any amount) to receive USDT and coupon
                      rewards. Eligible deposits or purchases can be made
                      through Fiat Deposit, P2P, Third-Party, Fast Trade, or
                      on-chain transfers. Please note that deposits or purchases
                      using assets from Red Envelopes or Trial Funds are not
                      eligible. Reward amounts will be randomly determined
                      within a set range.
                    </p>
                    <p>
                      {" "}
                      First Trade Reward: Complete your first trade (any amount)
                      to receive a USDT reward. Trades can be spot, futures,
                      margin, or bot trades. The reward amount will be randomly
                      determined within a set range. Zero-fee trades do not
                      count.
                    </p>
                    <p>
                      {" "}
                      Limited-Time Gift Pack: Complete your first trade within 7
                      days of signing up for your {webData?.website_title}{" "}
                      account to receive an additional gift pack, which includes
                      VIP Trial Coupons, Futures Deduction Coupons, Trading Bot
                      Fee Rebate Coupons, etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CalltoAction />
      <FooterNav />

      <Footer />
    </>
  );
}
